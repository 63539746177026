body {
    font-family: Avenir-Book, sans-serif;
    color: #333;
    margin:0;
}


html, body {
    overflow-x: hidden;
}
body {
    position: relative
}

@font-face {
    font-family: Avenir-Book;
    src: url('./fonts/avenir_book.woff2') format("woff2");
    font-weight: 400;
    font-style: normal
}


@font-face {
    font-family: Avenir-Medium;
    src: url('./fonts/avenir_medium.woff2') format("woff2");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: Avenir-Heavy;
    src: url('./fonts/Avenir_Heavy.ttf') format("truetype");
}

@font-face {
    font-family: ArgentCF-RegularItalic;
    src: url('./fonts/ArgentCF-RegularItalic.ttf') format("truetype");
}
